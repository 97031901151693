import './App.css';
import React, { Component } from 'react';
import Select from 'react-select';
import Web3 from "web3";
import Piece from "../src/artifacts/contracts/Piece.sol/Piece.json";
import nft from "./assets/aaliyah-1.jpg";

// require('dotenv').config();
// const alchemyKey = process.env.ROPSTEN_ALCHEMY_API_KEY;
// const { createAlchemyWeb3 } = require('@alch/alchemy-web3');
// const web3 = createAlchemyWeb3(alchemyKey);

// TODO: change when deploying to mainnet
const contractAddress = "0xCd31e2cF7219ef29A453Bb147f5C1101A77fF461";
var totalSupply = 72;

// TODO: change when deploying to mainnet
// const web3 = new Web3(new Web3.providers.HttpProvider("https://ropsten.infura.io/v3/63b857092d95490283809974a5d2a73e"));
const web3 = new Web3(Web3.givenProvider);
// const web3 = new Web3("localhost:3003");

const options = [
  // { value: 'Babe', label: 'Babe', prefix: 171},
  // { value: 'Baby', label: 'Baby', prefix: 181 },
  // { value: 'Bae', label: 'Bae', prefix: 191 },
  { value: 'Boo', label: 'Boo', prefix: 221 },
  // { value: 'Lover', label: 'Lover', prefix: 711 },
  // { value: 'My Love', label: 'My Love', prefix: 811 },
  { value: 'Aaliyah', label: 'Aaliyah', prefix: 1 },
  { value: 'Aaron', label: 'Aaron', prefix: 11  },
  { value: 'Abby', label: 'Abby', prefix: 21  },
  { value: 'Abigail', label: 'Abigail', prefix: 31  },
  { value: 'Addison', label: 'Addison', prefix: 41  },
  { value: 'Aiden', label: 'Aiden', prefix: 51  },
  // { value: 'Alex', label: 'Alex', prefix: 61  },
  { value: 'Ally', label: 'Ally', prefix: 71  },
  { value: 'Amelia', label: 'Amelia', prefix: 81  },
  { value: 'Andrew', label: 'Andrew', prefix: 91  },
  { value: 'Anna', label: 'Anna', prefix: 101  },
  { value: 'Anthony', label: 'Anthony', prefix: 111  },
  { value: 'Aria', label: 'Aria', prefix: 121  },
  { value: 'Aubrey', label: 'Aubrey', prefix: 131  },
  { value: 'Audrey', label: 'Audrey', prefix: 141  },
  { value: 'Ava', label: 'Ava', prefix: 151  },
  { value: 'Avery', label: 'Avery', prefix: 161  },
  { value: 'Bella', label: 'Bella', prefix: 201  },
  { value: 'Ben', label: 'Ben', prefix: 211  },
  { value: 'Brooke', label: 'Brooke', prefix: 231  },
  { value: 'Caleb', label: 'Caleb', prefix: 241  },
  { value: 'Camila', label: 'Camila', prefix: 251  },
  { value: 'Carter', label: 'Carter', prefix: 261  },
  { value: 'Charles', label: 'Charles', prefix: 271  },
  { value: 'Charlie', label: 'Charlie', prefix: 281  },
  { value: 'Charlotte', label: 'Charlotte', prefix: 291  },
  { value: 'Chloe', label: 'Chloe', prefix: 301  },
  { value: 'Chris', label: 'Chris', prefix: 311  },
  { value: 'Claire', label: 'Claire', prefix: 321  },
  // { value: 'Daniel', label: 'Daniel', prefix: 331  },
  { value: 'David', label: 'David', prefix: 341  },
  { value: 'Dylan', label: 'Dylan', prefix: 351  },
  { value: 'Elijah', label: 'Elijah', prefix: 361  },
  { value: 'Elizabeth', label: 'Elizabeth', prefix: 371  },
  { value: 'Ella', label: 'Ella', prefix: 381  },
  { value: 'Emily', label: 'Emily', prefix: 391  },
  { value: 'Emma', label: 'Emma', prefix: 401  },
  { value: 'Ethan', label: 'Ethan', prefix: 411  },
  { value: 'Evelyn', label: 'Evelyn', prefix: 421  },
  { value: 'Gabby', label: 'Gabby', prefix: 431  },
  { value: 'Gabe', label: 'Gabe', prefix: 441  },
  { value: 'Grace', label: 'Grace', prefix: 451  },
  { value: 'Hailey', label: 'Hailey', prefix: 461  },
  { value: 'Hannah', label: 'Hannah', prefix: 471  },
  { value: 'Harper', label: 'Harper', prefix: 481  },
  { value: 'Henry', label: 'Henry', prefix: 491  },
  { value: 'Hunter', label: 'Hunter', prefix: 501  },
  { value: 'Isaac', label: 'Isaac', prefix: 511  },
  { value: 'Isabella', label: 'Isabella', prefix: 521  },
  { value: 'Isaiah', label: 'Isaiah', prefix: 531  },
  // { value: 'Jack', label: 'Jack', prefix: 541  },
  { value: 'Jacob', label: 'Jacob', prefix: 551  },
  { value: 'Jadon', label: 'Jadon', prefix: 561  },
  { value: 'James', label: 'James', prefix: 571  },
  { value: 'Joe', label: 'Joe', prefix: 581  },
  { value: 'John', label: 'John', prefix: 591  },
  // { value: 'Jonathan', label: 'Jonathan', prefix: 601  },
  { value: 'Josh', label: 'Josh', prefix: 611  },
  { value: 'Julian', label: 'Julian', prefix: 621  },
  { value: 'Kayla', label: 'Kayla', prefix: 631  },
  { value: 'Layla', label: 'Layla', prefix: 641  },
  { value: 'Leah', label: 'Leah', prefix: 651  },
  { value: 'Liam', label: 'Liam', prefix: 661  },
  { value: 'Lily', label: 'Lily', prefix: 671  },
  { value: 'Liv', label: 'Liv', prefix: 681  },
  { value: 'Liz', label: 'Liz', prefix: 691  },
  { value: 'Logan', label: 'Logan', prefix: 701  },
  { value: 'Lucas', label: 'Lucas', prefix: 721  },
  { value: 'Luke', label: 'Luke', prefix: 731  },
  { value: 'Maddie', label: 'Maddie', prefix: 741  },
  { value: 'Madison', label: 'Madison', prefix: 751  },
  { value: 'Mason', label: 'Mason', prefix: 761  },
  { value: 'Matthew', label: 'Matthew', prefix: 771  },
  { value: 'Mia', label: 'Mia', prefix: 781  },
  { value: 'Michael', label: 'Michael', prefix: 791  },
  { value: 'Mila', label: 'Mila', prefix: 801  },
  { value: 'Natalie', label: 'Natalie', prefix: 821  },
  { value: 'Nate', label: 'Nate', prefix: 831  },
  { value: 'Nevaeh', label: 'Nevaeh', prefix: 841  },
  { value: 'Noah', label: 'Noah', prefix: 851  },
  { value: 'Oliver', label: 'Oliver', prefix: 861  },
  { value: 'Olivia', label: 'Olivia', prefix: 871  },
  { value: 'Owen', label: 'Owen', prefix: 881  },
  { value: 'Penelope', label: 'Penelope', prefix: 891  },
  { value: 'Riley', label: 'Riley', prefix: 901  },
  { value: 'Ryan', label: 'Ryan', prefix: 911  },
  // { value: 'Sam', label: 'Sam', prefix: 921  },
  { value: 'Sarah', label: 'Sarah', prefix: 931  },
  { value: 'Savannah', label: 'Savannah', prefix: 941  },
  { value: 'Scarlett', label: 'Scarlett', prefix: 951  },
  { value: 'Sebastian', label: 'Sebastian', prefix: 961  },
  { value: 'Sofia', label: 'Sofia', prefix: 971  },
  { value: 'Sophia', label: 'Sophia', prefix: 981  },
  { value: 'Stella', label: 'Stella', prefix: 991  },
  { value: 'Tom', label: 'Tom', prefix: 1001  },
  { value: 'Victoria', label: 'Victoria', prefix: 1011  },
  { value: 'Will', label: 'Will', prefix: 1021  },
  { value: 'Wyatt', label: 'Wyatt', prefix: 1031  },
  { value: 'Zach', label: 'Zach', prefix: 1041  },
  { value: 'Zoe', label: 'Zoe', prefix: 1051  }
]

class App extends Component {

  constructor(props) {
    super(props)
    this.state = {
      name: "",
      amount: 0,
      prefix: 0,
    }
    this.mintNFT = this.mintNFT.bind(this);
    this.handleMint = this.handleMint.bind(this);
    console.log(totalSupply);
  }
  
  //const [number, setNewNumber] = useState();

  async componentDidMount() {
    await this.loadWeb3()
    await this.loadBlockchainData()
  }

  async loadWeb3() {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum)
      await window.ethereum.enable()
    }
    else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider)
    }
    else {
      window.alert('Non-Ethereum browser detected. You should consider trying MetaMask!')
    }
  }

  async loadBlockchainData() {
    const web3 = window.web3
    // Load account
    const accounts = await web3.eth.getAccounts()
    this.setState({ account: accounts[0] })

    // TODO: change when deploying to mainnet
    const contractAddress = "0xd18F97eB8FeD05Cadfc3A5a1e2441155bE33Ba5A"; 
  }

  async mintNFT (namePrefix, amount) {
    // error handling
    if(totalSupply >= 200) {
      return {
        success: false,
        status: "❗Sold out! Stay tuned for our next collection.",
       }
    }
    if((namePrefix== 0 ) || amount == "" || amount == 0) {
      return {
        success: false,
        status: "❗Please make sure all fields are completed before attempting to mint.",
       }
    }

    if(amount > 10 || amount < 1) {
      return {
        success: false,
        status: "❗Please enter a valid amount of NFTs to mint.",
       }
    }
    // load smart contract
    window.contract = new web3.eth.Contract(Piece.abi, contractAddress);
    // set up your Ethereum transaction

    const amtInt = parseInt(amount);
    const namePrefixInt = parseInt(namePrefix);
    const cost = parseInt(web3.utils.toWei("0.1", "ether") * amtInt).toString(
      16
    );
    const gasCost = parseInt(200000).toString(16)
    console.log(cost);
    console.log(typeof(cost));
    const transactionParameters = {
      to: contractAddress,
      from: window.ethereum.selectedAddress,
      value: cost,
      gas: gasCost,
      'data': window.contract.methods.mint(amtInt,namePrefixInt).encodeABI()
    };

    // console.log(transactionParameters.value);

    // sign transaction via metamask
    try {
      const txHash = await window.ethereum
        .request({
          method: 'eth_sendTransaction',
          params: [transactionParameters],
        });
        totalSupply += amtInt;
      return {
        success: true,
        status: "✅ Congratulations! Check out your transaction on Opensea or Etherscan: https://etherscan.io/tx/" + txHash
      }
    } catch (error) {
        return {
          success: false,
          status: "😥 Something went wrong: " + error.message
        }
    }
  }

  async handleMint() {
    const { status } = await this.mintNFT(this.state.prefix, this.state.amount);
    this.setState({ status: status });
  }

  handleChange = (selectedOption) => {
    this.setState({ name: selectedOption.value });
    this.setState({ prefix: selectedOption.prefix });
  }

  handleInput = (e) => {
    this.setState({ amount: e.target.value });
  }

  styles = {
    option: (provided, state) => ({
      ...provided,
      fontWeight: state.isSelected ? "bold" : "normal",
      color: "black",
      backgroundColor: state.data.color,
      fontSize: state.selectProps.myFontSize
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "black",
      fontSize: state.selectProps.myFontSize
    })
  };
  
  render() { 
    const { selectedOption } = this.state;
    const { inputValue } = this.state;
    // console.log(this.state.name);
    // console.log(this.state.amount);
    // console.log(this.state.prefix);
    return (
      <div className="App">
        <header className="App-header">
          <a
            className="App-link"
            href="https://10kheartsnft.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            10K HEARTS NFT
          </a>
          {/*<p>
            Welcome to the 10K Hearts NFT minting site! Select your desired
            name, then press mint. You'll see a pop up from Metamask in the 
            upper right hand corner. Confirm the transaction in your wallet, 
            and it will be reflected in your wallet. Congratuations, you've
            just bought a Piece NFT!
          </p>*/}
          <img src={nft}/>
            <Select 
              className={'dropdown'}
              placeholder={'Type and select a name'}
              value={selectedOption} 
              options={options} 
              onChange={this.handleChange}
              styles={this.styles}
            />
          <div className="flexbox">
            <input 
              type="number"
              placeholder={'Amount to mint'} 
              value={inputValue} 
              onChange={this.handleInput}/>
            <button onClick={this.handleMint}>MINT</button>
          </div>
          {<p>{this.state.status}</p>}
        </header>
      </div>
    ); 
  }
}
export default App;
